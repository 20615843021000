import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderRoutingModule } from './header-routing.module';
import { HeaderComponent } from './header.component';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { TemplateMenuComponent } from './menus/template-menu.component';
import { BillingMenuComponent } from './menus/billing-menu.component';
import { CommunicationsMenuComponent } from './menus/communications-menu.component';
import { CompaniesMenuComponent } from './menus/companies-menu.component';
import { ProgramsMenuComponent } from './menus/programs-menu.component';
import { ContractsMenuComponent } from './menus/contracts-menu.component';
import { DocumentsMenuComponent } from './menus/documents-menu.component';
import { LookupsMenuComponent } from './menus/lookups-menu.component';
import { PayrollMenuComponent } from './menus/payroll-menu.component';
import { PayrollRateManagerMenuComponent } from './menus/payroll-rate-manager-menu.component';
import { PayrollValidationMenuComponent } from './menus/payroll-validation-menu.component';
import { CandidatesMenuComponent } from './menus/candidates-menu.component';
import { ReportsMenuComponent } from './menus/reports-menu.component';
import { TechnicalSpecialistsMenuComponent } from './menus/technical-specialists-menu.component';
import { TimecardsMenuComponent } from './menus/timecards-menu.component';
import { ProgramsLmcMenuComponent } from './menus/programs-lmc-menu.component';
import { TechnicalSpecialistsEyeExamsMenuComponent } from './menus/technical-specialists-eye-exams-menu.component';
import { WcMaintenanceMenuComponent } from './menus/wc-maintenance-menu.component';
import { TimecardsSplitMenuComponent } from './menus/timecards-split-menu.component';

@NgModule({
  declarations: [
    HeaderComponent,
    TemplateMenuComponent,
    BillingMenuComponent,
    CommunicationsMenuComponent,
    CompaniesMenuComponent,
    ProgramsMenuComponent,
    ContractsMenuComponent,
    DocumentsMenuComponent,
    LookupsMenuComponent,
    PayrollMenuComponent,
    PayrollRateManagerMenuComponent,
    PayrollValidationMenuComponent,
    CandidatesMenuComponent,
    ReportsMenuComponent,
    TechnicalSpecialistsMenuComponent,
    TimecardsMenuComponent,
    TimecardsSplitMenuComponent,
    ProgramsLmcMenuComponent,
    TechnicalSpecialistsEyeExamsMenuComponent,
    WcMaintenanceMenuComponent,
  ],
  imports: [
    CommonModule,
    HeaderRoutingModule,
    AppSharedModule
  ],
  exports: [
    HeaderComponent,
    PayrollRateManagerMenuComponent,
    PayrollValidationMenuComponent,
    ProgramsLmcMenuComponent,
    TechnicalSpecialistsEyeExamsMenuComponent,
    TimecardsSplitMenuComponent,
  ]
})
export class HeaderModule { }
