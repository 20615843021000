import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'timecards-split-menu',
    template: `
        <span class="mr-1" *ngIf="'Pages.Timecards.Splits.Admin' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='timecards-split-workfile'}" (click)="headerService.displaySubSubPage('timecards-split-workfile')" type="button">
                {{ 'Import Workfile' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Timecards.Splits.Admin' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='timecards-admin-split'}" (click)="headerService.displaySubSubPage('timecards-admin-split')" type="button">
                {{ 'Split Timecards' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Timecards.Splits.Admin' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='timecards-split-types'}" (click)="headerService.displaySubSubPage('timecards-split-types')" type="button">
                {{ 'Split Types' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Timecards.Splits.Admin' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='timecards-split-type-ts-list'}" (click)="headerService.displaySubSubPage('timecards-split-type-ts-list')" type="button">
                {{ 'Split Type TS List' | localize }}
            </button>
        </span>
        
    `,
    styles: ``,
})
export class TimecardsSplitMenuComponent extends AppComponentBase {}
