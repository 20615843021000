import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'programs-lmc-menu',
    template: `
        <span class="mr-1" *ngIf="'Pages.Programs.Lmc.Qbm' | permission">
            <button class="btn btn-sm btn-active-light-primary" (click)="headerService.displaySubSubPage('programs-lmc-qbm')" type="button">
                {{ 'QBM Admin' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Programs.Lmc.Workfile' | permission">
            <button class="btn btn-sm btn-active-light-primary" (click)="headerService.displaySubSubPage('programs-lmc-workfile')" type="button">
                {{ 'Workfile' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Programs.Lmc.DataChecks' | permission">
            <button class="btn btn-sm btn-active-light-primary" (click)="headerService.displaySubSubPage('programs-lmc-data-checks')" type="button">
                {{ 'DataChecks' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Programs.Lmc.Admin' | permission">
            <button class="btn btn-sm btn-active-light-primary" (click)="headerService.displaySubSubPage('programs-lmc-admin')" type="button">
                {{ 'Admin' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Programs.Lmc.Timecards' | permission">
            <button class="btn btn-sm btn-active-light-primary" (click)="headerService.displaySubSubPage('programs-lmc-timecards')" type="button">
                {{ 'Timecards' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Programs.Lmc.Invoices' | permission">
            <button class="btn btn-sm btn-active-light-primary" (click)="headerService.displaySubSubPage('programs-lmc-invoices')" type="button">
                {{ 'Invoices' | localize }}
            </button>
        </span>
    `,
    styles: ``,
})
export class ProgramsLmcMenuComponent extends AppComponentBase {}
