import { CommonModule } from '@angular/common';
import {  Component, Injector, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EmtDialogService } from './emt-dialog.service';

@Component({
  selector: 'emt-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './emt-dialog.component.html',
  styleUrl: './emt-dialog.component.css'
})
export class EmtDialogComponent {
   
  constructor(
    public bsModalRef: BsModalRef,
    public emtDialogService: EmtDialogService) {
    }
 
  ngOnInit() {
    this.emtDialogService.options.list.push('PROFIT!!!');
  }
}
