import { Injectable } from '@angular/core';
import { TimecardUpdateDto } from '@app/timecards/_models/legacy-timecard-update.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppMessagingService {
    private subject = new Subject<any>();
    private timecardSubject = new Subject<TimecardUpdateDto>();

    sendMessage(message: string) {
        this.subject.next({ text: message });
    }

    clearMessages() {
        this.subject.next(null);
    }

    onMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    timecardUpdate$ = this.timecardSubject.asObservable();

    updateTimecardList(timecardUpdate: TimecardUpdateDto) {
        this.timecardSubject.next(timecardUpdate);
    }

  constructor() { }
}
