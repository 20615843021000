import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'wc-maintenance-menu',
  template: `

    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='wc-maintenance-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('wc-maintenance-dashboard')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Dashboard' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='wc-maintenance-n8n-workflow'}" (click)="headerService.displaySubPage('wc-maintenance-n8n-workflow')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'N8N Workflow' | localize }}
        </button>
    </span>
  `,
  styles: ``
})
export class WcMaintenanceMenuComponent extends AppComponentBase {

}
