import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'page-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  animations: [appModuleAnimation()],
})

export class HeaderComponent extends AppComponentBase {
    
    current_route: string = this.router.url;

    constructor(
        injector: Injector,
        private router: Router
    ) {
        super(injector);
    }
}

