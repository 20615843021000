import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'payroll-rate-manager-menu',
    template: `
        <span class="mr-1" *ngIf="'Pages.Payroll.RateManager.PayRateRequest' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='payroll-pay-rate-request'}" (click)="headerService.displaySubSubPage('payroll-pay-rate-request')" type="button">
                {{ 'Pay rate Request' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.RateManager.BulkRateChanger' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='payroll-rate-manager-bulk-changer'}" (click)="headerService.displaySubSubPage('payroll-rate-manager-bulk-changer')" type="button">
                {{ 'Bulk Rate Changer' | localize }}
            </button>
        </span>
    `,
    styles: ``,
})
export class PayrollRateManagerMenuComponent extends AppComponentBase {}
