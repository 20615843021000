import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'newpost'
})
export class NewPostPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): unknown {
    if (moment().diff(moment(value), 'days') < 7) {
      //return '<fa-icon [icon]="icon.farSparkles" class="icon-green"></fa-icon>';
      return true;
    } else {
      return false;
    }
  }
}
