import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'template-menu',
  template: `
    <span class="mr-1">
        <button
            class="button-4"
            (click)="headerService.displaySubPage('Main Page - Sub Page')"
            type="button"
        >
            <fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>
            &nbsp;{{ 'xxx' | localize }} 
        </button>
    </span>
  `,
  styles: ``
})
export class TemplateMenuComponent extends AppComponentBase {

}
