<div class="content">
    <div class="display-group">
      <span class="label">Phone:</span> {{contact?.phone}}
    </div>  
    <div class="display-group">
      <span class="label">Mobile</span> {{contact?.mobile}}
    </div>  
    <div class="display-group">
      <span class="label">Email</span> {{contact?.Email}}
    </div>  
    <div class="display-group">
      <span class="label">Website</span> {{contact?.website}}
    </div>  
    <div class="display-group">
      <span class="label">Tags</span> {{contact?.tags}}
    </div>  
  </div>