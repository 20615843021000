import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'payroll-menu',
    template: `
        <span class="mr-1" *ngIf="'Pages.Payroll.Dashboard' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('payroll-dashboard')" type="button">
                <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
                {{ 'Dashboard' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.Statements' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-statements'}" (click)="headerService.displaySubPage('payroll-statements')" type="button">
                <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
                {{ 'Statements' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.Receipts' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-receipts'}" (click)="headerService.displaySubPage('payroll-receipts')" type="button">
                <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
                {{ 'Receipts' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.RateManager' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-rate-manager'}" (click)="headerService.displaySubPage('payroll-rate-manager')" type="button">
                <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
                {{ 'Rate Manager' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.Snapshot.Create' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-snapshot'}" (click)="headerService.displaySubPage('payroll-snapshot')" type="button">
                <fa-icon [icon]="icon.farPlus" class="mr-1"></fa-icon>
                {{ 'Snapshot' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.Validation' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-validation'}" (click)="headerService.displaySubPage('payroll-validation')" type="button">
                <fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>
                {{ 'Validation' | localize }}
            </button>
        </span>
        <span class="mr-1" *ngIf="'Pages.Payroll.Export.Create' | permission">
            <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='payroll-export'}" (click)="headerService.displaySubPage('payroll-export')" type="button">
                <fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>
                {{ 'Export' | localize }}
            </button>
        </span>
    `,
    styles: ``,
})
export class PayrollMenuComponent extends AppComponentBase {}
