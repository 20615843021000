import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'communications-menu',
  template: `
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='communications-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('communications-dashboard')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Dashboard' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='communications-com-logs'}" (click)="headerService.displaySubPage('communications-com-logs')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'ComLogs' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='communications-mailings'}" (click)="headerService.displaySubPage('communications-mailings')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Mailings' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='communications-alerts'}" (click)="headerService.displaySubPage('communications-alerts')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Alerts' | localize }}
        </button>
    </span>
  `,
  styles: ``
})
export class CommunicationsMenuComponent extends AppComponentBase {

}
