import { Component, Input } from '@angular/core';

@Component({
  selector: 'emt-address-display',
  templateUrl: './emt-address-display.component.html',
  styleUrl: './emt-address-display.component.css'
})
export class EmtAddressDisplayComponent {
  @Input() addressType: any;
  @Input() address: any;
}
