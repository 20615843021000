import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'technical-specialists-eye-exams-menu',
    template: `
        <span class="mr-1" *ngIf="'Pages.TechnicalSpecialists.EyeExams' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='technical-specialists-eye-exams-list'}" (click)="headerService.displaySubSubPage('technical-specialists-eye-exams-list')" type="button">
                {{ 'List' | localize }}
            </button>
        </span>
    `,
    styles: ``,
})
export class TechnicalSpecialistsEyeExamsMenuComponent extends AppComponentBase {}
